table {
    @apply min-w-full leading-normal mt-5;
}

thead {
    tr th {
        @apply px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider;
    }
}

tbody {
    tr {
        @apply border-b border-gray-200 bg-white text-sm text-gray-700;
    }

    td {
        @apply px-5 py-2.5;
    }
}

.table-action {
    @apply flex items-center cursor-pointer gap-2 py-1 px-2;

    svg {
        @apply w-4 h-4;
    }

    &.text-primary {
        @apply bg-indigo-50 rounded hover:bg-indigo-100 hover:text-indigo-600;
    }

    &.text-danger {
        @apply bg-red-50 rounded hover:bg-red-100 hover:text-red-600;
    }

    &.text-muted {
        @apply bg-gray-50 rounded hover:bg-gray-100 hover:text-gray-600;
    }
}
