@tailwind base;
@tailwind components;
@tailwind utilities;

$breakpoint-laptop: 1538px;
$breakpoint-small-laptop: 1024px;
$breakpoint-small-screen: 961px;
$breakpoint-tablet: 769px;
$breakpoint-phone: 769px;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    word-break: break-word;
    height: 100vh;
    @apply text-gray-700;

    .container {
        @apply py-6 max-w-8xl mx-auto px-4 sm:px-6 lg:px-8;
    }
}

//modules
@import 'modules/alert';
@import 'modules/card';
@import 'modules/input';
@import 'modules/typo';
@import 'modules/breadcrumb';
@import 'modules/table';
@import 'modules/button';
@import 'modules/modal';
@import 'modules/pagination';
@import 'modules/tag';
@import 'modules/footer';
@import 'modules/search';
@import 'home';

//tools
@import 'tools/flex';
@import 'tools/markdown';
@import 'tools/layout';

//libs
@import 'libs/highlight';
@import 'libs/markdowneditor';

//Admin
@import 'admin/dashboard';

//Pages
@import 'pages/profile';

//import lib
@import url('https://unpkg.com/highlightjs-copy/dist/highlightjs-copy.min.css');
