.tag {
    @apply px-3 py-1 rounded-full;
}

.tag-success {
    @apply bg-green-100 text-green-500;
}

.tag-warning {
    @apply bg-yellow-100 text-yellow-500;
}

.tag-danger {
    @apply bg-red-100 text-red-500;
}

.tag-primary {
    @apply bg-indigo-100 text-indigo-500;
}
