$buttonBg: rgba(236, 240, 241, 1);
$buttonTextColor: #626262;
$toolbarBg: #fafafa;
$toolbarBorder: #d8d7d7;
$outputAreaBg: #ffffff;
$previewMessageFontColor: #686868;
$previewMessageBg: #d6d6d6;

.markdown-editor * {
    box-sizing: border-box;
}

.markdown-editor {
    width: 100%;
    margin: 0 auto;
    position: relative;
    min-height: 500px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(#000, 0.2);
}

.markdown-editor .CodeMirror-cursor {
    width: 2px !important;
    background-color: blue !important;
}

.markdown-editor #input-output {
    width: 100%;
    padding: 8px;
}

.markdown-editor .CodeMirror-wrap {
    height: 100% !important;
}

.editor__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(#000, 0.2);
    padding: 0 8px;

    .editor__toolbar__group {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 2px;
        margin: 8px 0;
    }

    .icon {
        background: transparent;
        border: none;
        color: $buttonTextColor;
        font-family: sans-serif;
        font-size: 0.8rem;
        cursor: pointer;
        padding: 0.7rem;

        &:hover,
        &.active {
            background: darken($toolbarBg, 5%);
            border-radius: 5px;
            box-shadow: 0 0 2px lighten($toolbarBg, 5%) inset;
            outline: 0;
        }

        &:focus {
            outline: none;
        }
    }
}

#input-output {
    height: 100%;
    min-height: 400px;
    width: 95%;
    position: relative;

    #input-area {
        margin: 0;
        padding: 10px;
        border: 1px solid #aaaaaa;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 10px 10px #e0e0e0;
        resize: none;

        &:focus,
        &:active {
            outline: 0px;
        }
    }

    #output-area {
        background: #ffffff;
        border-radius: 0 0 10px 10px;
        display: none;
        font-family: sans-serif;
        padding: 10px;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 3;
        top: 1px;
        left: 1px;
        overflow-y: scroll;

        /* Titres */
        h1 {
            font-size: 24px;
            font-weight: bold;
        }

        h2 {
            font-size: 20px;
            font-weight: bold;
        }

        h3 {
            font-size: 18px;
            font-weight: bold;
        }

        /* Paragraphes */
        p {
            margin-bottom: 10px;
        }

        /* Liens */
        a {
            color: blue;
            text-decoration: underline;
        }

        /* Listes non ordonnées */
        ul {
            list-style-type: disc;
            margin-bottom: 10px;
        }

        /* Listes ordonnées */
        ol {
            list-style-type: decimal;
            margin-bottom: 10px;
        }

        /* Blocs de code */
        pre {
            padding: 10px 0;
            overflow: auto;

            code .hljs {
                padding: 8px !important;
            }
        }

        /* Citations */
        blockquote {
            border-left: 3px solid #b3b3b3;
            margin-left: 0;
            padding: 5px 10px 5px 25px;
        }

        /* Images */
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .show {
        display: block !important;
        height: auto;
    }
}
