.home {
    @apply grid grid-cols-6 gap-4;
}

.home .main {
    @apply col-span-4;
}

.section-secondary {
    @apply bg-indigo-50 border-b-2 border-indigo-100;
}

@media screen and (max-width: $breakpoint-laptop) {
    .home {
        @apply grid grid-cols-5 gap-4;
    }

    .articles {
        grid-template-columns: repeat(3, 1fr);
    }

    .home .main {
        @apply col-span-3;
    }
}
