.modal {
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}
