.alerts-wrapper {
    @apply flex flex-col-reverse fixed z-10 top-6 right-5 pt-1;
}

.alert {
    @apply flex justify-center items-center py-2 px-3.5 text-gray-500 bg-white rounded-lg shadow mb-3;
}

.alert-content {
    @apply ml-3 px-2 text-sm;
}

.alert-icon {
    @apply inline-flex flex-shrink-0 justify-center items-center w-8 h-8 rounded-lg;
}

.alert-close {
    @apply ml-auto mx-1.5 my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8;
}

.alert-success {
    .alert-icon {
        @apply text-green-500 bg-green-50;
    }
}

.alert-warning {
    .alert-icon {
        @apply text-yellow-500 bg-yellow-50;
    }
}

.alert-danger {
    .alert-icon {
        @apply text-red-500 bg-red-50;
    }
}
