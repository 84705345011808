.breadcrumb {
    @apply flex;
}

.breadcrumb__content {
    @apply inline-flex items-center space-x-1 md:space-x-3;
}

.breadcrumb__menu {
    @apply inline-flex items-center gap-2 text-gray-400;
}

.breadcrumb__menu a {
    @apply inline-flex items-center text-xs font-medium hover:text-indigo-500;
}
