.formatted {
    iframe {
        aspect-ratio: 16 / 9;
        width: 100%;
        height: auto;
    }

    & > *:last-child {
        margin-bottom: 0 !important;
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }

    blockquote {
        @apply border-l-4 border-gray-300 rounded;
        padding-left: 2rem;
        margin-left: 1rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        font-size: 1.2rem;

        p:last-child {
            margin-bottom: 0;
        }
    }

    > *:last-child {
        margin-bottom: 0 !important;
    }

    details > summary {
        list-style: none;
    }

    details > summary::-webkit-details-marker {
        display: none;
    }

    a {
        text-decoration: underline;

        &:hover {
            @apply text-indigo-500;
        }
    }

    pre {
        margin: 2em 0;
        overflow: auto;
        border: 1px solid var(--border-light);

        code {
            padding: 1em;
        }
    }

    p,
    ul,
    ol,
    blockquote {
        margin-top: 1rem;
        margin-bottom: 30px;
    }

    p,
    ul,
    ol {
        line-height: 1.6;
    }

    p:first-child {
        margin-top: 0 !important;
    }

    ul {
        list-style: disc;
        padding-left: 19px;
    }

    ol {
        list-style: decimal;
        padding-left: 19px;
    }

    img {
        max-width: 990px;
        width: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 990px) {
            max-width: 100vw;
        }
    }

    figure {
        padding: 0;
        margin: 30px;
        display: inline-block;
        position: relative;

        &.left {
            margin-left: 0;
            margin-top: 0;

            img {
                max-width: 275px;
            }
        }

        &.right {
            margin-right: 0;
            margin-top: 0;

            img {
                max-width: 275px;
            }
        }

        img {
            border: 4px solid #eeeeee;
            display: block;

            &[src$='.png'],
            &[src$='.svg'] {
                border: none;
            }
        }
    }

    p > code,
    li > code {
        display: inline-block;
        outline: solid 1px var(--border);
        padding: 0 0.5em;
        @apply bg-indigo-100 text-indigo-500;
    }

    .message {
        margin-bottom: 1rem;

        padding-left: 2rem;
        padding-bottom: 0.7rem;
        padding-top: 0.7rem;

        font-size: 1rem;

        @apply flex items-center;

        svg {
            @apply mr-8 w-6 h-6;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .message-warning {
        @apply border-l-4 border-yellow-400 rounded bg-yellow-100;

        svg {
            @apply text-yellow-500;
        }
    }

    .message-danger {
        @apply border-l-4 border-red-500 rounded bg-red-100;

        svg {
            @apply text-red-500;
        }
    }

    .message-info {
        @apply border-l-4 border-blue-500 rounded bg-blue-100;

        svg {
            @apply text-blue-500;
        }
    }
}
