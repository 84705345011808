.btn {
    @apply inline-flex gap-2 items-center px-4 py-2  border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest focus:outline-none focus:ring  disabled:opacity-25 transition ease-in-out duration-150;
}

.btn__primary {
    @apply bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-900 focus:border-indigo-900 ring-indigo-300;
}

.btn__secondary {
    @apply inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 transition ease-in-out duration-150;
}

.btn__danger {
    @apply bg-red-600 hover:bg-red-700 active:bg-red-900 focus:border-red-900 ring-red-300;
}

.btn__icon {
    @apply cursor-pointer hover:bg-gray-200 px-2 py-0.5 rounded-full;
}
