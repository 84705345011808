// Avatar
// =============
.profil-header__avatar {
    display: block;
    width: 110px;
    height: 110px;
    border-radius: 110px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @apply bg-gray-200 border border-gray-200;
}
.profil-header__avatar img,
.profil-header__svg {
    width: 100%;
    height: 100%;
    border-radius: 110px;
    transition: opacity 0.3s;
}
.profil-header__avatar input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.profil-header__body p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-light);
}
.profil-header__upload {
    width: 110px;
    height: 25px;
    background: var(--border);
    transform: translateY(100%);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #c3d3e3;

    .icon {
        width: 18px;
        height: 15px;
    }
}
.profil-header__avatar:hover .profil-header__upload {
    transform: translateY(0);
}
form.profil-header__avatar:hover img {
    opacity: 0.7;
}
