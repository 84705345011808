.pagination {
    @apply max-w-screen-xl mx-auto mt-6 px-4 text-gray-600 md:px-8 flex justify-center items-center;
}

.pagination-content {
    @apply hidden items-center justify-between sm:flex;

    ul {
        @apply flex justify-center items-center gap-1;
    }
}

.pagination-link {
    @apply px-3 py-2 flex;
}

.pagination-link-clicked {
    @apply px-3 py-2 rounded-lg duration-150 hover:text-indigo-600 hover:bg-indigo-50 flex cursor-pointer mx-1;
}

.pagination-active {
    @apply bg-indigo-50 text-indigo-600 font-medium rounded-lg flex cursor-pointer mx-1;
}

.pagination-dots {
    @apply px-3 py-2;
}
